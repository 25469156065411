:root {
    --yellow: #fceabd;
    --pink: #faf3f0;
    --pink-light: #faf8f7;
    --black: #121010;
    --breakpoint-smartphone: 320px;
    --breakpoint-tablet: 480px;
    --breakpoint-laptop: 768px;
    --breakpoint-desktop: 1024px;
    --breakpoint-large: 1200px;
    --typo-Akatab: 'Akatab', sans-serif;
    --typo-Barlow: 'Barlow', sans-serif;
    --typo-Barlow-Semi-Condensed: 'Barlow Semi Condensed', sans-serif;
    --typo-Lora: 'Lora', sans-serif;
    --typo-Noto-Sans: 'Noto Sans', sans-serif;
    --typo-Gabarito: 'Gabarito', sans-serif;
    --typo-Dosis: 'Dosis', sans-serif;
}

input[type='text'],
input[type='number'],
input:focus,
textarea {
    font-size: 16px;
}

body {
    min-height: -webkit-fill-available;
    /*font-family: var(--typo-Noto-Sans);*/
}

/*
font-family: 'Akatab', sans-serif;
font-family: 'AR One Sans', sans-serif;
font-family: 'Barlow', sans-serif;
font-family: 'Barlow Semi Condensed', sans-serif;
font-family: 'Dosis', sans-serif;
font-family: 'Gabarito', sans-serif;
font-family: 'Lora', serif;
font-family: 'PT Sans', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Sofia Sans', sans-serif; 
*/

.time {
    font-weight: 600;
    font-size: 25px;
    /*font-family: var(--typo-Barlow-Semi-Condensed);*/
    margin-right: 24px;
    margin-left: 80px;
}

h1 {
    font-size: 20px;
}

.h4-title {
    margin-bottom: 4px;
}

.App {
    text-align: center;
    box-sizing: border-box;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-bg {
    display: flex;
    justify-content: center;
    background-color: white;
    /*border: solid 1px red;*/
    /*height:100%*/
    /*min-height: -webkit-fill-available;*/
    /*height: 100 svh;*/
}

#container {
    /*border: solid 1px red;*/
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--pink);
    align-items: flex-start;
    /*min-width: 600px*/
}


#header {
    padding: 24px 0;
    background-color: white;
    /* border: solid 2px var(--pink); */
}

#navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%
}

/*#main {*/
/*  height: 100%;*/

/*}*/

.main-content {
    /*border: solid 1px red;*/
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    padding: 0 20px;
    font-family: "Sofia Sans";

}

.home {
    justify-content: space-evenly;
}

.home-subtitle {
    font-family: "Barlow Semi Condensed";
    font-size: 22px;
    margin-bottom: 8px;
    /*font-weight: bold;*/
    text-transform: uppercase;
}

.lieu {
    justify-content: flex-start;
    width: 100%;
    padding-top: 24px
    /*border: solid 1px red;*/
}

.lieu-mobile {
    justify-content: flex-start;
    padding-top: 30px
}

.infos {
    justify-content: flex-start;
    width: 100%;
    padding-top: 30px

}

.infos-mobile {
    justify-content: flex-start;
    padding-top: 30px

}

.section {
    font-family: "Sofia Sans";
    width: 80%;
    /* marginBottom: 40; */
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.date {
    margin-bottom: 12px;
}

.cool-title {
    font-size: 15px;
    font-weight: 600;
    color: var(--black);
    text-transform: uppercase;
}

.body-link {
    font-family: "Lora";
    font-family: "bely-display", sans-serif;

    font-size: 15px;
    /*font-style: italic;*/
    /*text-decoration: underline;*/
    font-weight: 500;
    text-decoration: none;
    color: var(--black);
    /*color: #118098;*/
    position: relative;

    /*  &:hover {
         text-decoration: underline;
         !* text-underline-offset: 0.3em; *!
       }*/

    /*[class*="a"]::before {*/
}

a[class*="body-link"]::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: var(--black);
    /*background-clip: #118098;*/
    opacity: 0.7;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
    /*border:solid 1px orange;*/

}


.navbar-link {
    font-family: "Barlow Semi Condensed";
    font-weight: 600;
    text-transform: uppercase;
    font-size: 21px;
    text-decoration: none;
    color: var(--black);
    position: relative;

    /*
    test 24px en condensed
    */
}

.navbar-link-is-active {
    text-decoration: underline;
    /*font-family: "Barlow Condensed";*/
    /*font-weight: 700;*/

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes bolder {
    100% {
        font-weight: bold;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
    /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
    /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* Styles */
}

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
    /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
    /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}
